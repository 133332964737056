import { useCallback, useContext, useEffect, useRef, useState } from 'react';

// material-ui
import {
  Badge,
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  IconButton,
  Paper,
  Popper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import MainCard from '../../../components/MainCard';
import ProfileAvatar from '../../../components/ProfileAvatar';
import Transitions from '../../../components/form-components/Transitions';
import { AuthenticationApi } from '../../../network/apis/AuthenticationApi';
import { User } from '../../../network/models/AccountModels';
import { AppStateContext } from '../../../utils/AppStateContext';
import {
  COLOR_BLUE,
  COLOR_GREEN,
  COLOR_LIGHT,
  COLOR_ORANGE,
  COLOR_RED,
  ERROR_SNACKBAR_OPTIONS,
  LOGIN_PATH,
  SETUPS_PATH,
  SOCKET_USER_ONLINE_STATUS,
  SUCCESS_SNACKBAR_OPTIONS,
} from '../../../utils/Constants';
import ProfileTab from './ProfileTab';
import { AuthActions } from '../../../network/AuthActions';
import { Notifications } from '@mui/icons-material';
import NotificationPopup from './NotificationPopup';
import SettingsIcon from '@mui/icons-material/Settings';

// tab panel wrapper
function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const Profile = () => {
  const theme = useTheme<any>();
  const { globalContentState, setGlobalContentState } =
    useContext(AppStateContext);
  const user: User = globalContentState.user;
  const authenticationApi: any = new AuthenticationApi();
  const navigate = useNavigate();
  const [logoutError, setLogoutError] = useState<any>(null);
  const [isLogout, setIsLogout] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [isOpenNotification, setIsOpenNotification] = useState<boolean>(false);
  const intervalIdRef = useRef<number | null>(null);
  const [ws, setWs] = useState<any>(null);
  const { enqueueSnackbar } = useSnackbar();
  const accountActions = new AuthActions();
  const location = useLocation();
  const notificationAnchorRef = useRef<any>(null);
  const navigateToPath = useCallback((path: string) => {
    navigate(path);
  }, []);

  const handleLogout = async () => {
    setIsLogout(true);
    authenticationApi.logout((is_successful: boolean, tokens_or_error: any) => {
      setIsLogout(false);
      if (is_successful) {
        localStorage.clear();
        navigate(LOGIN_PATH);
      } else {
        setLogoutError(tokens_or_error);
        enqueueSnackbar(logoutError, ERROR_SNACKBAR_OPTIONS);
      }
      enqueueSnackbar(
        `Logout: ${is_successful}`,
        is_successful ? SUCCESS_SNACKBAR_OPTIONS : ERROR_SNACKBAR_OPTIONS
      );
    });
  };

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const sendWebSocket = (newWs: WebSocket) => {
    if (newWs.readyState === WebSocket.OPEN) {
      newWs.send(JSON.stringify({ type: 'ping' }));
    } else {
      console.log('WebSocket is not in OPEN state');
      newWs.close();
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    }
  };

  useEffect(() => {
    const createWebSocket = () => {
      try {
        const tokens = accountActions.retrieveAuthenticationTokens();
        const newWs = new WebSocket(
          `${SOCKET_USER_ONLINE_STATUS}?token=${tokens?.access_token}`
        );
        setWs(newWs);
        return newWs;
      } catch (error) {
        console.error('Error creating WebSocket:', error);
        return null;
      }
    };

    const setupWebSocket = (newWs: WebSocket) => {
      if (newWs) {
        newWs.onopen = () => {
          console.log('User webSocket connected');
          sendWebSocket(newWs);
          intervalIdRef.current = window.setInterval(() => {
            sendWebSocket(newWs);
          }, 30000);
        };

        newWs.onmessage = (event: MessageEvent) => {
          const data = JSON.parse(event.data);
          if (data.type === 'live_data') {
            setUsers(data?.active_users);
            setGlobalContentState({
              ...globalContentState,
              userNotifications: {
                ...globalContentState?.userNotifications,
                mentions: data?.mentions,
                watch_list_alerts: data?.watch_list_alerts,
              },
            });
          }
        };

        newWs.onclose = (event: CloseEvent) => {
          console.log(
            `WebSocket connection closed. wasClean?: ${event?.wasClean}`
          );
          if (!event?.wasClean) {
            if (intervalIdRef.current) {
              clearInterval(intervalIdRef.current);
              intervalIdRef.current = null;
            }
            setUsers([]);
            setGlobalContentState({
              ...globalContentState,
              userNotifications: { mentions: [], watch_list_alerts: [] },
            });
            // Attempt to reconnect after a delay
            setTimeout(() => {
              const newWebSocket = createWebSocket();
              if (newWebSocket) setupWebSocket(newWebSocket);
            }, 5000); // Reconnect after 5 seconds
          }
        };

        newWs.onerror = (event: Event) => {
          console.error('WebSocket error:', event);
          newWs.close();
        };
      }
    };

    const newWs = createWebSocket();
    if (newWs) setupWebSocket(newWs);

    // Clean up function to close WebSocket connection when component unmounts
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    };
  }, []);

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <Stack direction="row" gap={1} alignItems="center">
        {users?.map((onlineUser: User, index: number) => (
          <Tooltip
            key={`${user?.initials}-${index}`}
            title={`${onlineUser?.first_name} ${onlineUser?.last_name}`}
            placement="top-start"
          >
            <Badge
              badgeContent=" "
              variant="dot"
              sx={{
                '& .MuiBadge-badge': { backgroundColor: COLOR_ORANGE },
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <ProfileAvatar
                avatarSx={{
                  objectFit: 'cover',
                  bgcolor: COLOR_GREEN,
                  width: '22px',
                  height: '22px',
                  color: COLOR_LIGHT,
                  fontSize: '12px',
                }}
                name={`${onlineUser?.first_name} ${onlineUser?.last_name}`}
              />
            </Badge>
          </Tooltip>
        ))}
        <IconButton
          aria-label="notifications"
          onClick={() => setIsOpenNotification(!isOpenNotification)}
          sx={{ color: COLOR_LIGHT, '&:hover': { bgcolor: COLOR_RED } }}
          ref={notificationAnchorRef}
        >
          <Badge
            badgeContent={
              (globalContentState?.userNotifications?.mentions?.length ?? 0) +
              (globalContentState?.userNotifications?.watch_list_alerts
                ?.length ?? 0)
            }
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Notifications />
          </Badge>
        </IconButton>
        <NotificationPopup
          open={isOpenNotification}
          setOpen={setIsOpenNotification}
          anchorEl={notificationAnchorRef?.current}
          notifications={globalContentState?.userNotifications}
        />
        <IconButton
          aria-label="setups"
          onClick={() => navigateToPath(SETUPS_PATH)}
          sx={{ color: COLOR_LIGHT, '&:hover': { bgcolor: COLOR_RED } }}
          ref={notificationAnchorRef}
        >
          <SettingsIcon />
        </IconButton>
        <ButtonBase
          sx={{
            p: 0.25,
            ml: '0!important',
            bgcolor: open ? COLOR_RED : 'transparent',
            borderRadius: 1,
            '&:hover': { bgcolor: COLOR_RED },
          }}
          aria-label="open profile"
          ref={anchorRef}
          aria-controls={open ? 'profile-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{ p: 0.5 }}
          >
            <ProfileAvatar
              avatarSx={{
                objectFit: 'cover',
                bgcolor: COLOR_RED,
                width: '32px',
                height: '32px',
                color: COLOR_LIGHT,
              }}
              name={`${user?.first_name} ${user?.last_name}`}
            />
            <Typography variant="subtitle1">
              {user?.first_name} {user?.last_name}
            </Typography>
          </Stack>
        </ButtonBase>
      </Stack>
      <Popper
        placement="bottom-end"
        open={open}
        onMouseLeave={() => setOpen(false)}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows?.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 250,
                  },
                  bgcolor: COLOR_BLUE,
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard
                    elevation={0}
                    border={false}
                    content={false}
                    sx={{ bgcolor: COLOR_BLUE }}
                  >
                    <CardContent sx={{ px: 2.5, pt: 3 }}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Stack
                            direction="row"
                            spacing={1.25}
                            alignItems="center"
                          >
                            <ProfileAvatar
                              avatarSx={{
                                objectFit: 'cover',
                                bgcolor: COLOR_RED,
                                width: '32px',
                                height: '32px',
                              }}
                              name={`${user?.first_name} ${user?.last_name}`}
                            />
                            <Stack>
                              <Typography color={COLOR_LIGHT} variant="h6">
                                {user?.first_name} {user?.last_name}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>
                    </CardContent>
                    {open && (
                      <>
                        <Box>
                          <ProfileTab user={user} handleLogout={handleLogout} />
                        </Box>
                      </>
                    )}
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
