import { CountryType, StateType } from '../../utils/Countries';
import { User } from './AccountModels';
import HelperFunctions from '../../utils/HelperFunctions';

export class Project {
  id: string;
  name?: string;
  job_number: string;
  customer: Customer | null;
  site: Site | null;
  broker: Company | null;
  broker_contact: Contact | null;
  channel_partner: Company | null;
  channel_partner_primary_contact: Contact | null;
  channel_partner_bdm: Contact | null;
  mag_sales_lead: User | null;
  mag_development_lead: User | null;
  template?: string | null;
  status: ProjectStatus | null;
  phase: string;
  scope: ProjectScope | null;
  notes: string;
  name_notes: string;
  stages?: any;

  purchase_type: string | null;
  capital_amount_budgeted: number | null;
  payback_cashflow_req: string | null;
  bid_requirements: string | null;
  tax_exemptions: string | null;
  union_requirements: string | null;
  funding_amount_pre_approved: number | null;
  funding_rate: string | null;

  battery: string | null;
  mounting_type: string | null;
  estimated_dc_capacity: number | null;
  estimated_ac_capacity: number | null;

  probability_of_close_proposed: number | null;
  date_proposed: string | null;
  date_contract: string | null;
  mag_project_value_proposed: number | null;
  mag_project_value_contract: number | null;
  partner_referral_fee_proposed: number | null;
  partner_referral_fee_contract: number | null;
  incentive_amount_proposed: number | null;
  incentive_amount_contract: number | null;
  proposed_incentive_assignment_to_mag: string | null;
  contract_incentive_assignment_to_mag: string | null;
  funded_value_proposed: number | null;
  funded_value_contract: number | null;
  kw_savings_proposed: number | null;
  kw_savings_contract: number | null;
  kwh_savings_proposed: number | null;
  kwh_savings_contract: number | null;
  mmbtu_savings_proposed: number | null;
  mmbtu_savings_contract: number | null;
  om_cost_savings_proposed: number | null;
  om_cost_savings_contract: number | null;
  energy_cost_savings_proposed: number | null;
  energy_cost_savings_contract: number | null;
  total_cost_savings_proposed: number | null;
  total_cost_savings_contract: number | null;
  carbon_savings_proposed: number | null;
  carbon_savings_contract: number | null;
  invoiced_to_date: ProjectInvoicedToDate[] | null;
  invoiced_to_date_total?: number | null;
  cost_to_date: number | null;
  balance_to_invoice: number | null;
  booked_cost: number | null;
  booked_margin: number | null;
  actual_margin: number | null;

  working_stage?: string | null;
  mag_contract_value_proposed?: number | null;
  mag_contract_value_contract?: number | null;
  total_tasks?: number | null;
  comments?: Comment[];
  recent_comment?: string;
  created?: string;
  survey_latest_complete_date?: string;
  installation_complete_date?: string;
  installation_start_date?: string;
  tasks_override?: boolean;
  recurring_requests_count?: number;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.job_number = parameters.job_number;
    this.name = parameters.name;
    this.customer = parameters.customer;
    this.site = parameters.site;
    this.broker = parameters.broker;
    this.broker_contact = parameters.broker_contact;
    this.channel_partner = parameters.channel_partner;
    this.channel_partner_primary_contact =
      parameters.channel_partner_primary_contact;
    this.channel_partner_bdm = parameters.channel_partner_bdm;
    this.mag_sales_lead = parameters.mag_sales_lead;
    this.mag_development_lead = parameters.mag_development_lead;
    this.status = parameters.status;
    this.phase = parameters.phase;
    this.scope = parameters.scope;
    this.notes = parameters.notes;
    this.name_notes = parameters.name_notes;

    this.purchase_type = parameters.purchase_type;
    this.capital_amount_budgeted = parameters.capital_amount_budgeted;
    this.payback_cashflow_req = parameters.payback_cashflow_req;
    this.bid_requirements = parameters.bid_requirements;
    this.tax_exemptions = parameters.tax_execmptions;
    this.union_requirements = parameters.union_requirements;
    this.funding_amount_pre_approved = parameters.funding_amount_pre_approved;
    this.funding_rate = parameters.funding_rate;

    this.mounting_type = parameters.mounting_type;
    this.battery = parameters.battery;
    this.estimated_dc_capacity = parameters.estimated_dc_capacity;
    this.estimated_ac_capacity = parameters.estimated_ac_capacity;

    this.probability_of_close_proposed =
      parameters.probability_of_close_proposed;
    this.date_proposed = parameters.date_proposed;
    this.date_contract = parameters.date_contract;
    this.mag_project_value_proposed = parameters.mag_project_value_proposed;
    this.mag_project_value_contract = parameters.mag_project_value_contract;
    this.partner_referral_fee_proposed =
      parameters.partner_referral_fee_proposed;
    this.partner_referral_fee_contract =
      parameters.partner_referral_fee_contract;
    this.incentive_amount_proposed = parameters.incentive_amount_proposed;
    this.incentive_amount_contract = parameters.incentive_amount_contract;
    this.proposed_incentive_assignment_to_mag =
      parameters.proposed_incentive_assignment_to_mag;
    this.contract_incentive_assignment_to_mag =
      parameters.contract_incentive_assignment_to_mag;
    this.funded_value_proposed = parameters.funded_value_proposed;
    this.funded_value_contract = parameters.funded_value_contract;
    this.kw_savings_proposed = parameters.kw_savings_proposed;
    this.kw_savings_contract = parameters.kw_savings_contract;
    this.kwh_savings_proposed = parameters.kwh_savings_proposed;
    this.kwh_savings_contract = parameters.kwh_savings_contract;
    this.mmbtu_savings_proposed = parameters.mmbtu_savings_proposed;
    this.mmbtu_savings_contract = parameters.mmbtu_savings_contract;
    this.om_cost_savings_proposed = parameters.om_cost_savings_proposed;
    this.om_cost_savings_contract = parameters.om_cost_savings_contract;
    this.energy_cost_savings_proposed = parameters.energy_cost_savings_proposed;
    this.energy_cost_savings_contract = parameters.energy_cost_savings_contract;
    this.total_cost_savings_proposed = parameters.total_cost_savings_proposed;
    this.total_cost_savings_contract = parameters.total_cost_savings_contract;
    this.carbon_savings_proposed = parameters.carbon_savings_proposed;
    this.carbon_savings_contract = parameters.carbon_savings_contract;
    this.invoiced_to_date = parameters.invoiced_to_date ?? [];
    this.invoiced_to_date_total = parameters.invoiced_to_date_total ?? 0;
    this.cost_to_date = parameters.cost_to_date;
    this.balance_to_invoice = parameters.balance_to_invoice;
    this.booked_cost = parameters.booked_cost;
    this.booked_margin = parameters.booked_margin;
    this.actual_margin = parameters.actual_margin;
    this.total_tasks = parameters.total_tasks;
    this.mag_contract_value_proposed = parameters.mag_contract_value_proposed;
    this.mag_contract_value_contract = parameters.mag_contract_value_contract;
    this.working_stage = parameters.working_stage;
    this.comments = parameters.comments ?? [];
    this.recent_comment = parameters.recent_comment;
    this.stages = parameters.stages;
    this.created = parameters.created;
    this.survey_latest_complete_date = parameters.survey_latest_complete_date;
    this.installation_complete_date = parameters.installation_complete_date;
    this.installation_start_date = parameters.installation_start_date;
    this.recurring_requests_count = parameters.recurring_requests_count;
  }
}

export class SiteContractor {
  id: number | string;
  // project: Project | null;
  company: string;
  contact_name: string;
  type: string;
  phone: string;
  email: string;
  notes: string;
  hour_rate: number | null;

  constructor(parameters: any) {
    this.id = parameters.id;
    // this.project = parameters.project;
    this.company = parameters.company;
    this.type = parameters.type;
    this.contact_name = parameters.contact_name;
    this.phone = parameters.phone;
    this.email = parameters.email;
    this.notes = parameters.notes;
    this.hour_rate = parameters.hour_rate;
  }
}

export class ProjectInvoicedToDate {
  id: number;
  date: string;
  amount: number;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.date = parameters.date;
    this.amount = parameters.amount;
  }
}

export class SiteContact {
  id: number | string;
  full_name: string;
  title: string;
  work_phone: string;
  home_phone: string;
  mobile_phone: string;
  business_fax: string;
  address: string;
  company: string;
  email: string;
  type: string;
  notes: string;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.full_name = parameters.full_name;
    this.title = parameters.title;
    this.work_phone = parameters.work_phone;
    this.home_phone = parameters.home_phone;
    this.mobile_phone = parameters.mobile_phone;
    this.business_fax = parameters.business_fax;
    this.address = parameters.address;
    this.company = parameters.company;
    this.email = parameters.email;
    this.type = parameters.type;
    this.notes = parameters.notes;
  }
}

export class HourRow {
  id: number;
  on: string | null;
  off: string | null;
  sunday: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  week: number;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.on = parameters.on;
    this.off = parameters.off;
    this.sunday = parameters.sunday;
    this.monday = parameters.monday;
    this.tuesday = parameters.tuesday;
    this.wednesday = parameters.wednesday;
    this.thursday = parameters.thursday;
    this.friday = parameters.friday;
    this.saturday = parameters.saturday;
    this.week = parameters.week;
  }
}

export class SiteHour {
  id: number;
  building: string;
  space: string;
  technology: string;
  control: string;
  notes: string;
  volts: string;
  hour_rows: HourRow[];

  constructor(parameters: any) {
    this.id = parameters.id;
    this.building = parameters.building;
    this.space = parameters.space;
    this.technology = parameters.technology;
    this.control = parameters.control;
    this.notes = parameters.notes;
    this.volts = parameters.volts;
    this.hour_rows = parameters.hour_rows ?? [];
  }
}

export class Customer {
  id: string;
  name: string;
  number: string;
  total_projects?: number;
  total_sites?: number;
  total_surveyed?: number;
  total_built?: number;
  total_under_contract?: number;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.number = parameters.number;
    this.name = parameters.name;
    this.total_sites = parameters.total_sites;
    this.total_projects = parameters.total_projects;
    this.total_surveyed = parameters.total_surveyed;
    this.total_built = parameters.total_built;
    this.total_under_contract = parameters.total_under_contract;
  }
}

export class Site {
  id: string;
  number: string;
  customer_site?: string;
  name?: string;
  customer: Customer | any;
  address: string;
  city: string;
  zip: string;
  google_location_url: string;
  country: CountryType | null;
  state: StateType | null;
  website: string;
  business_unit: string;
  notes: string;

  //facility
  industry: string | null;
  year_built: string | null;
  square_ft: number | null;
  building_use: string | null;
  electric_company: string | null;
  gas_company: string | null;
  kwh: number | null;
  mcf: number | null;
  own_lease: string | null;
  lease_yrs_remaining: number | null;

  mask: boolean;
  glasses: boolean;
  steel_toe: boolean;
  vest: boolean;
  hearing_protection: boolean;
  hard_hat: boolean;
  guest_network: string | null;
  guest_network_password: string | null;

  //documents
  electricity_bills: string | null;
  gas_bills: string | null;
  drawings: string | null;
  photos: string | null;
  energy_study: string | null;
  lighting_study: string | null;
  cmp_air_study: string | null;
  hvac_study: string | null;
  solar_study: string | null;
  facility_notes: string | null;
  contractors: SiteContractor[] | null;
  contacts: SiteContact[] | null;
  hours: SiteHour[] | null;

  //lighting
  battery_balasts: boolean;
  bug_eye: boolean;
  generator: boolean;
  inverter: boolean;
  twenty_four_seven_areas: string | null;
  percent_twenty_four_seven: string | null;
  fifty_plus_deg_areas: string | null;
  classified_areas: string | null;
  specialty_fixures: string | null;
  preferred_spec: string | null;
  reloc_bus_bar: string | null;
  wiring_issues: string | null;
  installation_issues: string | null;
  power_issues: string | null;
  light_level: string | null;
  burnouts: string | null;
  maintenance_type: string | null;
  maintenance_contractor: string | null;
  other_issues: string | null;
  lighting_notes: string | null;

  total_projects?: number | null;
  is_imported?: boolean;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.customer_site = parameters.customer_site;
    this.number = parameters.number;
    this.name = parameters.name;
    this.customer = parameters.customer;
    this.address = parameters.address;
    this.city = parameters.city;
    this.zip = parameters.zip;
    this.google_location_url = parameters.google_location_url;
    this.country = parameters.country;
    this.state = parameters.state;
    this.website = parameters.website;
    this.business_unit = parameters.business_unit;
    this.notes = parameters.notes;

    //facility
    this.industry = parameters.industry;
    this.year_built = parameters.year_built;
    this.square_ft = parameters.square_ft;
    this.building_use = parameters.building_use;
    this.electric_company = parameters.electric_company;
    this.gas_company = parameters.gas_company;
    this.kwh = parameters.kwh;
    this.mcf = parameters.mcf;
    this.own_lease = parameters.own_lease;
    this.lease_yrs_remaining = parameters.lease_yrs_remaining;

    this.mask = parameters.mask;
    this.glasses = parameters.glasses;
    this.steel_toe = parameters.steel_toe;
    this.vest = parameters.vest;
    this.hearing_protection = parameters.hearing_protection;
    this.hard_hat = parameters.hard_hat;
    this.guest_network = parameters.guest_network;
    this.guest_network_password = parameters.guest_network_password;

    //documents
    this.electricity_bills = parameters.electricity_bills;
    this.gas_bills = parameters.gas_bills;
    this.drawings = parameters.drawings;
    this.photos = parameters.photos;
    this.energy_study = parameters.energy_study;
    this.lighting_study = parameters.lighting_study;
    this.cmp_air_study = parameters.cmp_air_study;
    this.hvac_study = parameters.hvac_study;
    this.solar_study = parameters.solar_study;
    this.facility_notes = parameters.facility_notes;
    this.contractors = parameters.contractors ?? [];
    this.contacts = parameters.contacts ?? [];
    this.hours = parameters.hours ?? [];

    //lighting
    this.battery_balasts = parameters.battery_balasts;
    this.bug_eye = parameters.bug_eye;
    this.generator = parameters.generator;
    this.inverter = parameters.inverter;
    this.twenty_four_seven_areas = parameters.twenty_four_seven_areas;
    this.percent_twenty_four_seven = parameters.percent_twenty_four_seven;
    this.fifty_plus_deg_areas = parameters.fifty_plus_deg_areas;
    this.classified_areas = parameters.classified_areas;
    this.specialty_fixures = parameters.specialty_fixures;
    this.preferred_spec = parameters.preferred_spec;
    this.reloc_bus_bar = parameters.reloc_bus_bar;
    this.wiring_issues = parameters.wiring_issues;
    this.installation_issues = parameters.installation_issues;
    this.power_issues = parameters.power_issues;
    this.light_level = parameters.light_level;
    this.burnouts = parameters.burnouts;
    this.maintenance_type = parameters.maintenance_type;
    this.maintenance_contractor = parameters.maintenance_contractor;
    this.other_issues = parameters.other_issues;
    this.lighting_notes = parameters.lighting_notes;
    this.total_projects = parameters.total_projects;
    this.is_imported = parameters.is_imported;
  }
}

export class Company {
  id: string;
  name: string;
  website: string;
  type: string;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.name = parameters.name;
    this.website = parameters.website;
    this.type = parameters.type;
  }
}

export class CompanyType {
  id: string;
  name: string;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.name = parameters.name;
  }
}

export class Contact {
  id: string;
  first_name: string;
  last_name: string;
  full_name?: string;
  email: string;
  phone: string;
  company: Company | any;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.first_name = parameters.first_name;
    this.last_name = parameters.last_name;
    this.full_name = parameters.full_name;
    this.email = parameters.email;
    this.phone = parameters.phone;
    this.company = parameters.company;
  }
}

export class ProjectStatus {
  id: string;
  name: string;
  badge_color: string;
  text_color: string;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.name = parameters.name;
    this.badge_color = parameters.badge_color;
    this.text_color = parameters.text_color;
  }
}

export class ProjectScope {
  id: string;
  code: string;
  number: number | '';
  badge_color: string;
  description: string;
  text_color: string;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.code = parameters.code;
    this.number = parameters.number;
    this.badge_color = parameters.badge_color;
    this.description = parameters.description;
    this.text_color = parameters.text_color;
  }
}

export class ProjectStage {
  id: string;
  name?: string;
  number: number | '';
  code: string;
  badge_color: string;
  text_color: string;
  description: string;
  status?: string;
  highest?: string;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.name = parameters.name;
    this.number = parameters.number;
    this.code = parameters.code;
    this.badge_color = parameters.badge_color;
    this.text_color = parameters.text_color;
    this.description = parameters.description;
    this.status = parameters.status;
    this.highest = parameters.highest;
  }
}

export class TaskStatus {
  id: string;
  code: string;
  description: string;
  badge_color: string;
  text_color: string;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.code = parameters.code;
    this.description = parameters.description;
    this.badge_color = parameters.badge_color;
    this.text_color = parameters.text_color;
  }
}

export class EmailRecipient {
  id: string;
  email: string;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.email = parameters.email;
  }
}

export class Task {
  id: string;
  name: string;
  number?: number | null;
  project?: Project | any;
  is_template?: boolean;
  template?: TaskTemplate | any;
  status?: TaskStatus | any;
  stage?: ProjectStage | any;
  stage_name?: string;
  mag_lead?: TaskTeam | null;
  team?: TaskTeam[] | any;
  hours?: string;
  days?: number;
  priority?: string;
  start_date?: string | null;
  due_date?: string | null;
  sub_tasks?: Task[];
  comments?: Comment[];
  recurring_requests_count?: number;
  is_watched_by_user?: boolean;
  parent_task?: string | null;
  parent_task_details?: Task;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.name = parameters.name;
    this.number = parameters.number;
    this.project = parameters.project;
    this.is_template = parameters.is_template;
    this.template = parameters.template;
    this.status = parameters.status;
    this.days = parameters.days;
    this.stage = parameters.stage;
    this.stage_name = parameters.stage_name;
    this.mag_lead = parameters.mag_lead;
    this.team = parameters.team ?? [];
    this.hours = parameters.hours;
    this.priority = parameters.priority;
    this.start_date = HelperFunctions.formatDate(parameters.start_date);
    this.due_date = parameters.due_date;
    this.sub_tasks = HelperFunctions.getSortedArrayOfObjects(
      'asc',
      parameters.sub_tasks ?? [],
      'number'
    );
    this.comments = parameters.comments ?? [];
    this.recurring_requests_count = parameters.recurring_requests_count ?? 0;
    this.is_watched_by_user = parameters.is_watched_by_user;
    this.parent_task = parameters.parent_task;
    this.parent_task_details = parameters.parent_task_details;
  }
}

export class TaskTeamSummary {
  id: number;
  stats: {
    total_completed: number;
    total_lead: number;
    due_next_week: number;
    due_this_week: number;
    due_today: number;
    past_due: number;
    total_opened: number;
  };
  user: {
    id: string;
    fist_name: string;
    last_name: string;
    team_member: string;
  };

  constructor(parameters: any) {
    this.id = parameters.id;
    this.user = parameters.user;
    this.stats = parameters.stats;
  }
}

export class TaskTemplate {
  id: string;
  name: string;
  tasks?: Task[];

  constructor(parameters: any) {
    this.id = parameters.id;
    this.name = parameters.name;
    this.tasks = parameters.tasks ?? [];
  }
}

export class TaskTeam {
  id: string;
  user_id: string;
  initials: string;
  first_name: string;
  last_name: string;
  task_id: string;
  is_lead: boolean;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.user_id = parameters.user_id;
    this.initials = parameters.initials;
    this.first_name = parameters.first_name;
    this.last_name = parameters.last_name;
    this.task_id = parameters.task_id;
    this.is_lead = parameters.is_lead;
  }
}

export class TaskWatchList {
  id: string;
  user: User;
  task: string;
  task_details: Task;
  project: string;
  project_details: Project;
  comments: Comment[];
  recurring_requests_count: number;
  thread_comments_count: number;
  user_total_mentions: number;
  created: string;
  alert_time: string;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.user = parameters.user;
    this.task = parameters.task;
    this.task_details = parameters.task_details;
    this.project = parameters.project;
    this.project_details = parameters.project_details;
    this.comments = parameters.comments;
    this.recurring_requests_count = parameters.recurring_requests_count;
    this.thread_comments_count = parameters.thread_comments_count;
    this.user_total_mentions = parameters.user_total_mentions;
    this.created = parameters.created;
    this.alert_time = parameters.alert_time;
  }
}

export class AppSearch {
  customers: Customer[];
  projects: Project[];
  tasks: Task[];
  sites: Site[];

  constructor(parameters: any) {
    this.customers = parameters.customers ?? [];
    this.projects = parameters.projects ?? [];
    this.tasks = parameters.tasks ?? [];
    this.sites = parameters.sites ?? [];
  }
}

export class Comment {
  id: string;
  user?: { id: string; name: string; initials: string } | any;
  created?: string;
  content: string;
  data_name?: string;
  project?: { id: string; name: string; job_number: string } | any;
  is_from_form?: boolean;
  form_user?: string;
  task?:
    | {
        id: string;
        name: string;
        project_name: string;
        stage: ProjectStage;
        team: TaskTeam[];
        parent_task: {
          id: string;
          name: string;
          stage: ProjectStage;
          project_name: string;
          team: TaskTeam[];
        };
      }
    | any;
  mentions: string[];
  user_total_mentions?: number;
  thread_comments_count?: number;
  comment_mentions?: {
    user: {
      id: string;
      initials: string;
      full_name: string;
    };
    is_read: boolean;
  }[];
  recurring_requests_count?: number;
  email_request?: {
    user_name: string;
    user_company: string;
  };

  constructor(parameters: any) {
    this.id = parameters.id;
    this.user = parameters.user;
    this.created = parameters.created;
    this.content = parameters.content;
    this.project = parameters.project;
    this.task = parameters.task;
    this.mentions = parameters.mentions;
    this.user_total_mentions = parameters.user_total_mentions;
    this.thread_comments_count = parameters.thread_comments_count;
    this.comment_mentions = parameters.comment_mentions;
    this.data_name = parameters.data_name;
    this.recurring_requests_count = parameters.recurring_requests_count;
    this.is_from_form = parameters.is_from_form;
    this.form_user = parameters.form_user;
  }
}

export class RecurringRequest {
  id: string;
  user_details: User;
  task_details: Task;
  email_details?: string;
  task_initial_comments_count: number;
  task_initial_status: string;
  active: boolean;
  team: any[];
  team_details?: TaskTeam[];
  frequency: number;
  frequency_unit: string;
  until: string;
  occurrences: number;
  run_times: number;
  message: string;
  email_me: boolean;
  last_run: string;
  is_outside_user?: boolean;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.user_details = parameters.user_details;
    this.task_details = parameters.task_details;
    this.email_details = parameters.email_details;
    this.task_initial_comments_count = parameters.task_initial_comments_count;
    this.task_initial_status = parameters.task_initial_status;
    this.active = parameters.active;
    this.team = parameters.team;
    this.team_details = parameters.team_details;
    this.frequency = parameters.frequency;
    this.frequency_unit = parameters.frequency_unit;
    this.until = parameters.until;
    this.occurrences = parameters.occurrences;
    this.run_times = parameters.run_times;
    this.message = parameters.message;
    this.email_me = parameters.email_me;
    this.last_run = parameters.last_run;
    this.is_outside_user = parameters.is_outside_user;
  }
}

export class UserMention {
  id: string;
  is_read: boolean;
  comment: {
    id: string;
    user: {
      id: string;
      initials: string;
      full_name: string;
    };
    task: {
      id: string;
      name: string;
      parent_task: {
        id: string;
        name: string;
        project_name: string;
      };
    };
    project: {
      id: string;
      name: string;
    };
    created: string;
  };

  constructor(parameters: any) {
    this.id = parameters.id;
    this.is_read = parameters.is_read;
    this.comment = parameters.comment;
  }
}

export class Report {
  id: string;
  selected_columns: string[];
  hidden_columns: string[];
  new_column_names: any[];
  column_filters: any;
  created?: string;
  created_by?: User;
  report_name: string;
  show_recent_comment: boolean;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.selected_columns = parameters.selected_columns;
    this.hidden_columns = parameters.hidden_columns;
    this.new_column_names = parameters.new_column_names;
    this.column_filters = parameters.column_filters;
    this.created = parameters.created;
    this.created_by = parameters.created_by;
    this.report_name = parameters.report_name;
    this.show_recent_comment = parameters.show_recent_comment;
  }
}

export class KanbanBoard {
  id: string;
  name?: string;
  columns: KanbanBoardColumn[];
  created_by?: User;
  created?: string;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.name = parameters.name;
    this.columns = parameters.columns;
    this.created = parameters.created;
    this.created_by = parameters.created_by;
  }
}

export class KanbanBoardColumn {
  id: string;
  column_name: string;
  column_color: string;
  column_cards: KanbanBoardColumnCard[];

  constructor(parameters: any) {
    this.id = parameters.id;
    this.column_name = parameters.column_name;
    this.column_color = parameters.column_color;
    this.column_cards = parameters.column_cards;
  }
}

export class KanbanBoardColumnCard {
  id: string;
  columnId: string;
  project?: Project;
  project_id?: string;
  task?: Task;
  task_id?: string;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.columnId = parameters.columnId;
    this.project = parameters.project;
    this.project_id = parameters.project_id;
    this.task = parameters.task;
    this.task_id = parameters.task_id;
  }
}

export class TableView {
  id: string;
  name?: string;
  view_to_override?: string;
  table_settings: any;
  data_name?: string;
  created_by?: User;
  created?: string;

  constructor(parameters: any) {
    this.id = parameters.id;
    this.name = parameters.name;
    this.table_settings = parameters.table_settings;
    this.data_name = parameters.data_name;
    this.created = parameters.created;
    this.created_by = parameters.created_by;
  }
}
