import { createContext, useMemo, useState } from 'react';
import { AuthActions } from '../network/AuthActions';
import { User } from '../network/models/AccountModels';

interface AppContextInterface {
  user: User;
  userNotifications: {
    mentions: any[];
    watch_list_alerts: any[];
  };
}

const AppStateContext = createContext<any>({});

const AppContextProvider = (props: any) => {
  const { children } = props;
  const accountActions: any = new AuthActions();
  const [globalContentState, setGlobalContentState] =
    useState<AppContextInterface>({
      user: accountActions.retrieveAuthenticatedUser(),
      userNotifications: { mentions: [], watch_list_alerts: [] },
    });
  const value = useMemo(
    () => ({ globalContentState, setGlobalContentState }),
    [globalContentState]
  );

  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
};

export { AppContextProvider, AppStateContext };
