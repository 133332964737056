import {
  Button,
  ClickAwayListener,
  Divider,
  Link,
  Popper,
  Stack,
  Typography,
} from '@mui/material';
import {
  APP_BUTTON_SX,
  COLOR_DARK,
  COLOR_LIGHT,
  COLOR_ORANGE,
  NOTIFICATIONS_PATH,
  PROJECTS_PATH,
  TASKS_PATH,
} from '../../../utils/Constants';
import * as React from 'react';
import HelperFunctions from '../../../utils/HelperFunctions';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { TaskWatchList } from '../../../network/models/AppModels';

interface NotificationPopupProps {
  anchorEl: any;
  open: boolean;
  setOpen: (val: boolean) => void;
  notifications: {
    mentions: any[];
    watch_list_alerts: any[];
  };
}

function NotificationPopup(props: NotificationPopupProps) {
  const { anchorEl, open, setOpen, notifications } = props;
  const navigate = useNavigate();

  return (
    <Popper
      anchorEl={anchorEl}
      open={open}
      placement={'bottom-end'}
      sx={{
        zIndex: 10000,
        boxShadow:
          'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;',
      }}
    >
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Box>
          <Stack
            spacing={2}
            sx={{
              p: 1,
              width: '300px',
              maxHeight: '450px',
              overflow: 'auto',
              bgcolor: COLOR_DARK,
            }}
          >
            {notifications?.mentions?.map((mention: any, index: number) => (
              <Stack
                key={`${mention?.comment?.id}-${index}`}
                sx={{
                  backgroundColor: COLOR_LIGHT,
                  borderRadius: 1,
                  paddingX: 1,
                }}
              >
                <Typography>
                  {mention?.comment?.user?.full_name ?? ''} mentioned you on{' '}
                  {getMentionLink(mention)}
                </Typography>
                <Divider sx={{ opacity: 0.6 }} />
                <Typography
                  variant="caption"
                  sx={{
                    color: '#979288',
                    paddingX: 1,
                    fontSize: '10px',
                  }}
                >
                  {HelperFunctions.formatTimeStampToLocale(
                    mention?.comment.created
                  )}
                </Typography>
              </Stack>
            ))}
            {notifications?.watch_list_alerts?.map(
              (watch_list: TaskWatchList, index: number) => (
                <Stack
                  key={`${watch_list?.id}-${index}`}
                  sx={{
                    backgroundColor: COLOR_LIGHT,
                    borderRadius: 1,
                    paddingX: 1,
                  }}
                >
                  <Typography>
                    Task was updated: {getWatchAlertLink(watch_list)}
                  </Typography>
                  <Divider sx={{ opacity: 0.6 }} />
                  <Typography
                    variant="caption"
                    sx={{
                      color: '#979288',
                      paddingX: 1,
                      fontSize: '10px',
                    }}
                  >
                    {watch_list?.alert_time
                      ? HelperFunctions.formatTimeStampToLocale(
                          watch_list?.alert_time
                        )
                      : ''}
                  </Typography>
                </Stack>
              )
            )}
          </Stack>
          {(notifications?.mentions?.length > 0 ||
            notifications?.watch_list_alerts?.length > 0) && (
            <Button
              variant="contained"
              size="small"
              sx={{
                ...APP_BUTTON_SX,
                bgcolor: COLOR_ORANGE,
                border: 'none',
                borderRadius: '0',
                // color: COLOR_DARK,
                width: '100%',
                my: 0,
              }}
              onClick={(e) => {
                navigate(NOTIFICATIONS_PATH);
                setOpen(false);
              }}
            >
              View ALL
            </Button>
          )}
        </Box>
      </ClickAwayListener>
    </Popper>
  );
}

export default NotificationPopup;

export const getMentionLink = (mention: any) => {
  if (mention?.comment?.project?.id !== '' && mention?.comment?.project?.id) {
    return (
      <Link
        href={`${PROJECTS_PATH}?${HelperFunctions.objectToCommaQueryString({ p_name: mention?.comment?.project?.name || '' })}`}
      >
        {mention?.comment?.project?.name ?? ''}
      </Link>
    );
  } else if (mention?.comment?.task?.id !== '' && mention?.comment?.task?.id) {
    let paramName = mention?.comment?.task?.name ? 't_name' : 't_id';
    let paramValue = mention?.comment?.task?.name
      ? mention?.comment?.task?.name
      : mention?.comment?.task?.id
        ? mention?.comment?.task?.id
        : '';

    let project_name = mention?.comment?.task?.project_name;
    if (mention?.comment?.task?.parent_task) {
      paramValue = mention?.comment?.task?.parent_task?.name
        ? mention?.comment?.task?.parent_task?.name
        : mention?.comment?.task?.parent_task?.id
          ? mention?.comment?.task?.parent_task?.id
          : '';
      paramName = mention?.comment?.task?.parent_task?.name ? 't_name' : 't_id';
      project_name = mention?.comment?.task?.parent_task?.project_name;
    }
    return (
      <span>
        <Link
          href={`${TASKS_PATH}?${HelperFunctions.objectToCommaQueryString({
            [paramName]: paramValue,
            p_name: project_name || '',
            ...(mention?.comment?.task?.parent_task
              ? { t_expanded: mention?.comment?.task?.parent_task?.id }
              : {}),
          })}`}
        >
          Task
        </Link>
        {project_name ? ' for ' : ''}
        <Link
          href={`${PROJECTS_PATH}?${HelperFunctions.objectToCommaQueryString({ p_name: project_name || '' })}`}
        >
          {project_name ? project_name : ''}
        </Link>
      </span>
    );
  } else {
    return '';
  }
};
export const getWatchAlertLink = (watch_list: TaskWatchList) => {
  return (
    <Link
      href={`${TASKS_PATH}?${HelperFunctions.objectToCommaQueryString({
        [watch_list?.task_details?.parent_task_details
          ? 't_name'
          : watch_list?.task_details?.name
            ? 't_name'
            : 't_id']: watch_list?.task_details?.parent_task_details
          ? watch_list?.task_details?.parent_task_details?.name
          : watch_list?.task_details?.name
            ? watch_list?.task_details?.name
            : watch_list?.task_details?.id,
        p_name: watch_list?.task_details?.parent_task_details
          ? watch_list?.task_details?.parent_task_details?.project?.name
          : watch_list?.task_details?.project?.name || '',
        ...(watch_list?.task_details?.parent_task_details
          ? { t_expanded: watch_list?.task_details?.parent_task_details?.id }
          : {}),
      })}`}
    >
      {watch_list.task_details?.name || 'Blank name'}
    </Link>
  );
};
